import React, { useState } from 'react';
import MainForm from './components/MainForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThankYou from './components/ThankYou';
import Layout from './components/Layout';
import AllowlistInactive from './components/AllowlistInactive';




function App() {

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formdata, setFormdata] = useState('');
  const formEnabled = process.env.REACT_APP_ALLOWLIST_STATUS !== 'inactive';


  return (
    <div >
      <Layout goBack={() => {
        setFormSubmitted(false);
        setFormdata('');
      }}
      >
        {!formEnabled ? (<AllowlistInactive />) : (
          <>
            {!formSubmitted ? <MainForm submitSuccess={() => setFormSubmitted(true)} setFormdata={setFormdata} /> : <ThankYou formdata={formdata} />}
          </>
        )}

      </Layout>
    </div>
  );
}

export default App;
