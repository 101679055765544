import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'

const AllowlistInactive = () => {

	return (
		<>
			<h1 className='text-center heading mt-5'>
				We're sorry. The Allowlist is not currently open. <br />

			</h1>
			<br />
			<h4 className="heading text-center">
				Please join our <a href="https://discord.gg/Q7sB6rwAGE" className="out-link heading">Discord</a> community to learn more about the Allowlist.
			</h4>
			<br />
			<div className="heading text-center center">
			</div>

		</>

	);
};

export default AllowlistInactive;